<template>
	<div class="contributors">
        <div class="text-center my-5 title">
            <img src="@/assets/our-contributors/our-contributors.png" alt="">
            <h1 class="mt-md-1">Our Key Committees </h1>
        </div>
        <div class="offset-1 col-10 mb-5" v-for="(contributor, contributor_index) in contributors" :key="contributor_index">
            <div class="sector my-4 px-3 py-2"><h2>{{ contributor.sector }}</h2></div>
            <div v-for="(data, data_index) in contributor.data" :key="data_index">
                <div class="mb-3">
                    <h2>{{ data.section }}</h2>
                    <p class="font-weight-bold" v-if="data.sectionDateRange">{{ data.sectionDateRange }}</p>
                </div>
                <div class="row">
                    <div class="profile col-md-4 my-3" v-for="member in data.members" :key="member.name">
                        
                        <h4>{{ member.name }}</h4>
                        <h5 v-if="member.position">{{ member.position }}</h5>
                        <h6 v-html='member.info'></h6>
                        <div class='date' v-for="dateRange in member.dateRanges" :key="dateRange" 
                            v-html='dateRange'>
                        </div>
                    </div>
                </div>

                <!-- Horizontal line !--> 
                <div v-if="data_index != contributor.data.length - 1" class="mt-4 mb-5" style="border-top: 1px solid #DA3305;"></div>
            </div>
        </div>

        <div class="mx-3">
            <PageNavigation prev="/our-leadership" next="/" />
        </div>
            

        

	</div>	
</template>

<script>
import Contributors from '@/components/contributors/contributors.js'
import PageNavigation from '@/components/PageNavigation'

export default {
    name: 'Contributors',

    data(){
        return {
            contributors : Contributors
        }
    },

    components: {
        PageNavigation
    },    
}
</script>

<style scoped>
.contributors {
    margin-top: 100px;
    min-height: 100vh;
}

.sector {
    background: #FFAD00;
    display: inline-block;
    border-radius: .25rem;
}

h1 {
    color: #DA3305;
    font-size: 38px;
    font-family: 'Montserrat SemiBold';
}

h2 {
    font-family: 'Montserrat SemiBold';
    font-size: 21px;
    margin-bottom: 0px;
    color: #363636;
}

h4 {
    font-family: 'Montserrat SemiBold';
    color: #DA3305;
    margin: 3px 0;
    font-size: 21px;
}

h5 {
    font-family: 'Montserrat SemiBold';
    color: #363636;
    font-size: 18px;
    margin:  3px 0;
}

h6, .date {
    font-family: 'Frutiger Neue LT Book';
    font-size: 18px;
    margin: 3px 0;
}

h6 {
    color: #363636;
    white-space: pre-wrap;
}

p {
    font-family: 'Montserrat SemiBold';
    font-size: 21;
}

.date {
    color: #808080;
    font-size: 16px;
}

@media (min-width: 760px) {
    .profile {
        padding-right: 20px;
    }
}

@media(max-width: 768px) {
    .contributors {
        margin-top: 100px;
    }
    .title {
        margin-bottom: 60px !important;
    }

    img {
        width: 66px;
    }
    h1 {
        font-size: 24px !important;
    }
    h2 {
        font-size: 18px;
    }
    h4 {
        font-size: 18px;
    }
    h5 {
        font-size: 16px;
    }
    h6, .date {
        font-size: 16px;
    }
}

</style>