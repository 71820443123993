export default [
    {
        sector: "Sector Development",
        data: [
            {
                "section": "Community Care Manpower Committee",
                "members": [
                    {
                        "name": "Mr Tim Oei",
                        "position": "Chairman",
                        "info": "Chief Executive Officer, National Kidney Foundation",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020"]
                    },
                    {
                        "name": "Mr Joe Hau",
                        "position": "Chairman",
                        "info": "Chief Executive Officer, Ren Ci Hospital",
                        "dateRanges": ["1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Cheng Siok Khoong",
                        "position": "Member",
                        "info": "Chief Executive Officer, Bright Hill Evergreen Home",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020", "1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Choo Shiu Ling",
                        "position": "Member",
                        "info": "Chief Executive Officer, Assisi Hospice",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020", "1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Christina Tiong",
                        "position": "Member",
                        "info": "Chief Executive Officer, Home Nursing Foundation",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020", "1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Mr James Tan",
                        "position": "Member",
                        "info": "Chief Executive Officer, TOUCH Community Services",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020", "1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Mr Jason Foo",
                        "position": "Member",
                        "info": "Chief Executive Officer, Alzheimer’s Disease Association",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020"]
                    },
                    {
                        "name": "Mr Jason Lee",
                        "position": "Member",
                        "info": "Acting Chief Executive Officer, Thye Hua Kwan Moral Charities",
                        "dateRanges": ["1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "A/Prof Kenny Tan",
                        "position": "Member",
                        "info": "Chief Executive Officer, St Luke’s Eldercare",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020", "1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Lavinia Low",
                        "position": "Member",
                        "info": "Director (Manpower Planning & Strategy), Ministry of Health",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020", "1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Ngo Lee Yian",
                        "position": "Member",
                        "info": "Executive Director, Singapore Association for Mental Health",
                        "dateRanges": ["1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Ong Hui Ming",
                        "position": "Member",
                        "info": "Deputy Chief Executive Officer, Econ Healthcare (Asia) Limited",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020", "1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Ow Chee Chung",
                        "position": "Member",
                        "info": "Chief Executive Officer, Kwong Wai Shiu Hospital",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020"]
                    },
                    {
                        "name": "Mr Samuel Tan",
                        "position": "Member",
                        "info": "Chief Executive Officer, All Saints Home",
                        "dateRanges": ["1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Mr Then Kim Yuan",
                        "position": "Member",
                        "info": "Administrator, Lee Ah Mooi Old Age Home",
                        "dateRanges": ["1 January 2019 to 31&nbsp;December&nbsp;2020", "1 January 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                ],
            },
            {
                "section": "Community Care Manpower Development Award Selection Committee",
                "members": [
                    {
                        "name": "Mr Tan Kwang Cheak",
                        "position": "Chairman",
                        "info": "Chief Executive Officer, Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Alison Sim",
                        "position": "Member",
                        "info": "Director of Nursing, St Andrew’s Community Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Ang Bee Lian",
                        "position": "Member",
                        "info": "Director of Social Welfare, Ministry of Social and Family Development",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Chua Chi Siong",
                        "position": "Member",
                        "info": "Medical Director, Jurong Community Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Dennie Hsu, PB",
                        "position": "Member",
                        "info": "Director, Clinical Support Services, National University Health System",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Florence Cheong",
                        "position": "Member",
                        "info": "Head of Department, Occupational Therapy, Tan Tock Seng Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Gribson Chan",
                        "position": "Member",
                        "info": "Deputy Director, Rehabilitation, St Luke’s Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Lavinia Low",
                        "position": "Member",
                        "info": "Director, Manpower Planning & Strategy, Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Long Chey May",
                        "position": "Member",
                        "info": "Group Chief Patient Officer, National University Health System",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Ng Gaik Nai",
                        "position": "Member",
                        "info": "Deputy Group Chief Nurse of SingHealth, KK Women’s and Children’s Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Ng Li-Ling",
                        "position": "Member",
                        "info": "Senior Consultant, Department of Psychological Medicine, Changi General Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "A/Prof Swapna Verma",
                        "position": "Member",
                        "info": "Chief, Departments of Psychosis and East Region, Institute of Mental Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr R. Akhileswaran",
                        "position": "Member",
                        "info": "Medical Consultant, Khoo Teck Puat Hospital",
                        "dateRanges": []
                    },
                ]
            },
            {
                "section": "National Advance Care Planning (ACP) Steering Committee",
                "members": [
                    {
                        "name": "Prof Pang Weng Sun",
                        "position": "Chairperson",
                        "info": "Deputy Group Chief Executive Officer, Population Health, National Healthcare Group",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Ng Han Lip, Raymond",
                        "position": "Co-Chairperson",
                        "info": "Head and Senior Consultant, Woodlands Health Campus",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Adeline Lam",
                        "position": "Member",
                        "info": "Senior Consultant, Tan Tock Seng Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Angel Lee",
                        "position": "Member",
                        "info": "Medical Director and Senior Consultant,\nSt. Andrew’s Community Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "A/Prof Chan Mei Yoke",
                        "position": "Member",
                        "info": "Senior Consultant, KK Women’s and Children’s Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Chee Wai Yee",
                        "position": "Member",
                        "info": "Senior Director, Montfort Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Christina Loh",
                        "position": "Member",
                        "info": "Director of Nursing, Allium Care Suites",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Dennis Seow Chuen Chai",
                        "position": "Member",
                        "info": "Senior Consultant, Singapore General Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Genevieve Wong Cheng Sim",
                        "position": "Member",
                        "info": "Head Medical Social Worker, Singapore General Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Adj A/Prof James Low",
                        "position": "Member",
                        "info": "Senior Consultant, Khoo Teck Puat Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Koh Lip Hoe",
                        "position": "Member",
                        "info": "Senior Consultant, Changi General Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "A/Prof Melvin Chua",
                        "position": "Member",
                        "info": "Head and Senior Consultant, Sengkang General Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Noreen Chan",
                        "position": "Member",
                        "info": "Senior Consultant, National University Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Norhisham Bin Main",
                        "position": "Member",
                        "info": "Director, Head of Division & Senior Consultant (Supportive Care & Palliative Medicine), Senior Consultant (Geriatric Medicine)",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Priyanka Khatri",
                        "position": "Member",
                        "info": "Consultant, Alexandra Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Sumytra Menon",
                        "position": "Member",
                        "info": "Senior Assistant Director, National University of Singapore",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Susan Lim Pui San",
                        "position": "Member",
                        "info": "Family Physician, Principal Staff, National Healthcare Group Polyclinics",
                        "dateRanges": []
                    },
                    {
                        "name": "Prof William Hwang",
                        "position": "Member",
                        "info": "Medical Director and Senior Consultant, <br>National Cancer Centre Singapore",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Winifred Lau",
                        "position": "Member",
                        "info": "Chief (Primary and Community Care Development Division), Agency for Integrated Care",
                        "dateRanges": []
                    },
                ]
            },
            {
                "section": "National General Practitioners Advisory Panel (NGPAP)",
                "members": [
                    {
                        "name": "Prof Chee Yam Cheng",
                        "position": "Chairperson",
                        "info": "President, NHG College, National Healthcare Group President, Singapore Medical Council",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Tham Tat Yean",
                        "position": "Co-Chairperson",
                        "info": "Chief Executive Officer, Frontier Healthcare Group",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Adrian Ee",
                        "position": "Member",
                        "info": "Chief Executive Officer, SingHealth Polyclinics",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Cheong Siew Meng James",
                        "position": "Member",
                        "info": "Family Physician, C3 Family Clinic @ Aljunied Crescent",
                        "dateRanges": []
                    },
                    {
                        "name": "A/Prof Chong Phui-Nah",
                        "position": "Member",
                        "info": "Chief Executive Officer, National Healthcare Group Polyclinics",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Jacqueline Yam",
                        "position": "Member",
                        "info": "Medical Director, AcuMed Medical Group",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Jimmy Chew Kwong Yik",
                        "position": "Member",
                        "info": "Medical Director, OneCare Medical",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Kwek Thiam Soo",
                        "position": "Member",
                        "info": "Family Physician, Bukit Batok Medical Clinic",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Lee Yik Voon",
                        "position": "Member",
                        "info": "Family Physician, Lee & Tan Family Clinic and Surgery",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Leong Choon Kit",
                        "position": "Member",
                        "info": "Family Physician, Mission Medical Clinic",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Lew Yii Jen",
                        "position": "Member",
                        "info": "Chief Executive Officer, National University Polyclinics",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Loke Kam Weng",
                        "position": "Member",
                        "info": "Family Physician, Keat Hong Family Medicine Clinic",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Lye Tong Fong",
                        "position": "Member",
                        "info": "Medical Director, Central 24-HR Clinic Group",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Ruth Lim",
                        "position": "Member",
                        "info": "Director (Primary Care and Community Care), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Tammy Chan",
                        "position": "Member",
                        "info": "2nd Vice President, 61st Singapore Medical Association Council",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Tan Teck Jack",
                        "position": "Member",
                        "info": "Medical Director, Northeast Medical Group",
                        "dateRanges": []
                    },
                    {
                        "name": "Adj. A/Prof Tan Tze Lee",
                        "position": "Member",
                        "info": "President, College of Family Physicians Singapore",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Winifred Lau",
                        "position": "Member",
                        "info": "Chief (Primary and Community Care Development Division), Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Wong Tien Hua",
                        "position": "Member",
                        "info": "Family Physician, Mutual Healthcare",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Zuraimi Bin Mohamed Dahlan",
                        "position": "Member",
                        "info": "Chairman, Medifund Committee of Jamiyah Nursing Home <br>Member, External Placement Board Review (Prisons) and Board of Visitors (Prisons-DRC) <br>Member, Tribunal of Maintenance of Parents",
                        "dateRanges": []
                    },
                ]
            },
            {
                "section": "National InterRAI Steering Committee",
                "members": [
                    {
                        "name": "A/Prof Kenneth Mak",
                        "position": "Chairperson",
                        "info": "Director (Medical Services), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Benjamin Koh",
                        "position": "Advisor",
                        "info": "Deputy Secretary (Development), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Angel Lee",
                        "position": "Community Care Sector Representative",
                        "info": "Director (Medical Services), St. Andrew’s Community Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Carolina Png",
                        "position": "Community Care Sector Representative",
                        "info": "Director (Care and Rehabilitation Services), Vanguard Healthcare",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Christina Tiong",
                        "position": "Community Care Sector Representative",
                        "info": "Chief Executive Officer, Home Nursing Foundation",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Chua Chee Yong",
                        "position": "IHiS Representative",
                        "info": "Head (Emerging Services & Capabilities Group), Integrated Health Information Systems",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Dan Yock Young",
                        "position": "MOH Representative",
                        "info": "Deputy Director (Medical Services), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Germaine Ong",
                        "position": "MOH Representative",
                        "info": "Assistant Director (Home & Long Term Care), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Henry Sim",
                        "position": "MOH Representative",
                        "info": "Deputy Director (Clinical Outcome, Benchmarking & Value), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Jack Sim",
                        "position": "Community Care Sector Representative",
                        "info": "Centre Director, Geylang East Nursing Home, NTUC",
                        "dateRanges": []
                    },
                    {
                        "name": "Mdm Low Mui Lang",
                        "position": "Community Care Sector Representative",
                        "info": "Executive Director, Peacehaven Nursing Home",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Ng Wai Chong",
                        "position": "InterRAI Fellow (SG)",
                        "info": "Chief Executive Officer, NWC Longevity Practice <br>Clinical Programme Consultant, Tsao Foundation <br>Consultant, Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Tan Kwang Cheak",
                        "position": "AIC Representative",
                        "info": "Chief Executive Officer, Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Teh Shi-Hua",
                        "position": "MOH Representative",
                        "info": "Director (Subvention), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Titus Lee",
                        "position": "MOH Representative",
                        "info": "Director (Aged Care Services), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Winifred Lau",
                        "position": "AIC Representative",
                        "info": "Chief (Primary and Community Care Development Division), Agency for Integrated Care",
                        "dateRanges": []
                    },
                ]
            },
            {
                "section": "Primary Care Networks (PCN) Council",
                "members": [
                    {
                        "name": "Dr Tham Tat Yean",
                        "position": "Chairperson",
                        "info": "Chief Executive Officer, Frontier Healthcare Group",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Dr Kok Mun Foong",
                        "position": "Co-Chairperson",
                        "info": "Group Chief, Home First Group, <br>Agency for Integrated Care",
                        "dateRanges": ["1 July 2018 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Ms Winifred Lau",
                        "position": "Co-Chairperson",
                        "info": "Chief, Primary and Community Care Development Division, Agency for Integrated Care",
                        "dateRanges": ["1 April 2020 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Dr Jacqueline Yam",
                        "position": null,
                        "info": "Clinical Lead, Assurance PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Koh Eng Hoe",
                        "position": null,
                        "info": "Admin Lead, Assurance PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Eng Soo Kiang",
                        "position": null,
                        "info": "Clinical Lead, Central North PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Doraisamy Gowri",
                        "position": null,
                        "info": "Admin Lead, Central-North PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Leong Choon Kit",
                        "position": null,
                        "info": "Clinical Lead, Class PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Paul Ang",
                        "position": null,
                        "info": "Admin Lead, Class PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;August&nbsp;2019"]
                    },
                    {
                        "name": "Dr Fadzil Bin Jaafar",
                        "position": null,
                        "info": "Admin Lead, Class PCN",
                        "dateRanges": ["1 September 2019 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Chong Chin Kwang",
                        "position": null,
                        "info": "Clinical Lead, Frontier PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Anne Yeo",
                        "position": null,
                        "info": "Admin Lead, Frontier PCN",
                        "dateRanges": ["1 July 2018 to 30&nbsp;June&nbsp;2019"]
                    },
                    {
                        "name": "Dr Lee Wen Yan",
                        "position": null,
                        "info": "Admin Lead, Frontier PCN",
                        "dateRanges": ["1 July 2019 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Lim Chien Chuan",
                        "position": null,
                        "info": "Clinical Lead, I-CARE PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Chi Wei Ming",
                        "position": null,
                        "info": "Admin Lead, I-CARE PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Kwong Kum Hoong",
                        "position": null,
                        "info": "Clinical Lead, NUHS PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2019"]
                    },
                    {
                        "name": "Dr Kwek Thiam Soo",
                        "position": null,
                        "info": "Clinical Lead, NUHS PCN",
                        "dateRanges": ["1 January 2020 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Dr Ho Han Kwee",
                        "position": null,
                        "info": "Admin Lead, NUHS PCN",
                        "dateRanges": ["1 July 2018 to 3&nbsp;May&nbsp;2020"]
                    },
                    {
                        "name": "Ms Joanne Yap",
                        "position": null,
                        "info": "Admin Lead, NUHS PCN",
                        "dateRanges": ["4 May 2020 to 2&nbsp;August&nbsp;2020"]
                    },
                    {
                        "name": "Dr Richard Hui",
                        "position": null,
                        "info": "Admin Lead, NUHS PCN",
                        "dateRanges": ["3 August 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Jason Yap Soo Kor",
                        "position": null,
                        "info": "Clinical Lead, Parkway Shenton PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Mr Ang Chee Chiang",
                        "position": null,
                        "info": "Admin Lead, Parkway Shenton PCN",
                        "dateRanges": ["1 July 2018 to 30&nbsp;June&nbsp;2020"]
                    },
                    {
                        "name": "Mr Ang Chee Wee",
                        "position": null,
                        "info": "Admin Lead, Parkway Shenton PCN",
                        "dateRanges": ["1 July 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Chng Shih Kiat",
                        "position": null,
                        "info": "Clinical Lead, Raffles Medical PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Mr Yong Yih Ming",
                        "position": null,
                        "info": "Admin Lead, Raffles Medical PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;May&nbsp;2021"]
                    },
                    {
                        "name": "Dr Kenneth Wu",
                        "position": null,
                        "info": "Admin Lead, Raffles Medical PCN",
                        "dateRanges": ["1 June 2021 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Lily Aw",
                        "position": null,
                        "info": "Clinical Lead, SingHealth Partners PCN <br>(SingHealth DOT PCN)",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Emily Ho",
                        "position": null,
                        "info": "Admin Lead, SingHealth Partners PCN <br>(SingHealth DOT PCN)",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Rick Chan Wei Chyet",
                        "position": null,
                        "info": "Clinical Lead, SingHealth Partners PCN <br>(SingHealth Regional PCN)",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Lely Gunawan",
                        "position": null,
                        "info": "Admin Lead, SingHealth Partners PCN <br>(SingHealth Regional PCN)",
                        "dateRanges": ["1 July 2018 to 12&nbsp;April&nbsp;2019"]
                    },
                    {
                        "name": "Dr Winston Ong",
                        "position": null,
                        "info": "Admin Lead, SingHealth Partners PCN <br>(SingHealth Regional PCN)",
                        "dateRanges": ["13 April 2019 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Chee Boon Ping",
                        "position": null,
                        "info": "Clinical Lead, United PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Tan Teck Jack",
                        "position": null,
                        "info": "Admin Lead, United PCN",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2022"]
                    },
                ]
            },
            {
                "section": "Research & Innovative Committee (RIC)",
                "members": [
                    {
                        "name": "Dr Wong Loong Mun",
                        "position": "Co-Chairperson",
                        "info": "Chief, Care Integration and Operations Division\nCustomer Experience Department\nAgency for Integrated Care",
                        "dateRanges": ["1 July 2018 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "A/Prof Tan Boon Yeow",
                        "position": "Co-Chairperson",
                        "info": "Chief Executive Officer,\nSt Luke’s Hospital",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2019"]
                    },
                    {
                        "name": "Dr Chua Chi Siong",
                        "position": "Co-Chairperson",
                        "info": "Medical Director\nJurong Community Hospital",
                        "dateRanges": ["1 January 2020 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Dr Chong Poh Heng",
                        "position": "Member",
                        "info": "Medical Director\nHCA Hospice Care",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "A/Prof Corinne Ghoh",
                        "position": "Member",
                        "info": "Consultant, Ageing Planning\nMinistry of Health\n\nCo-Director, Next Age Institute \nAssociate, Social Services Research Centre \nNational University of Singapore",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "A/Prof Joanne Yoong",
                        "position": "Member",
                        "info": "Senior Economist and Director,\nCenter for Economic and Social Research\nUniversity of Southern California (USC)\n\nFounder and CEO\nResearch for Impact",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Ms Chan Mei Mei",
                        "position": "Member",
                        "info": "Director of Nursing, Home Nursing Foundation",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2019"]
                    },
                    {
                        "name": "Mr Chern Siang Jye",
                        "position": "Member",
                        "info": "Group Chief,\nSector and Partnerships Division\nCare System Integration Division\nAgency for Integrated Care",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2019"]
                    },
                    {
                        "name": "Ms Chong Wai Fung",
                        "position": "Member",
                        "info": "Centre Director, NTUC Health\n(Jurong West Nursing Home)",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2019"]
                    },
                    {
                        "name": "Dr Wong Chek Hooi",
                        "position": "Member",
                        "info": "Chief, Clinical Affairs & Research\nTSAO Foundation\n\nAdjunct Assistant Professor, Health Services\nand Systems Research\nDukes-NUS Graduate Medical School",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2019"]
                    },
                    {
                        "name": "Prof Doris Young",
                        "position": "Member",
                        "info": "Head, Department of Family Medicine,\nNational University Health System\n\nProfessor, Division of Family Medicine, Yong Loo Lin School of Medicine, National University of Singapore",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2019"]
                    },
                    {
                        "name": "Dr Amanda Margaret Conne",
                        "position": "Member",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2020"]
                    },
                    {
                        "name": "Prof Karen Chua",
                        "position": "Member",
                        "info": "Senior Consultant (Rehabilitation Medicine),\nTan Tock Seng Hospital",
                        "dateRanges": ["1 August 2018 to 31&nbsp;December&nbsp;2019"]
                    },
                    {
                        "name": "Asst Prof Rahul Malhotra",
                        "position": "Member",
                        "info": "Assistant Professor,\nHealth Services and Systems Research (HSSR)\nHead of Research, Centre for Ageing Research and Education (CARE)\nDuke-NUS Medical School",
                        "dateRanges": ["1 January 2020 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Prof Miao Chun Yan",
                        "position": "Member",
                        "info": "Founding Director\nNTU-UBC Research Centre of Excellence in Active Living for the Elderly (LILY)",
                        "dateRanges": ["1 January 2020 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Dr Ng Wai Chong",
                        "position": "Member",
                        "info": "Founder & CEO of NWC Longevity Practice Clinical Programme Director, TSAO Foundation",
                        "dateRanges": ["1 January 2020 to 31&nbsp;December&nbsp;2020"]
                    },
                    {
                        "name": "A/Prof Mythily Subramaniam",
                        "position": "Member",
                        "info": "Director, Research Division\nInstitute of Mental Health",
                        "dateRanges": ["1 January 2020 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Mr Gribson Chan",
                        "position": "Member",
                        "info": "Deputy Director, Rehabilitation\nSt Luke’s Hospital",
                        "dateRanges": ["1 January 2021 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "Dr Angel Lee",
                        "position": "Member",
                        "info": "Medical Director\nSt Andrew’s Community Hospital",
                        "dateRanges": ["1 January 2021 to 31&nbsp;December&nbsp;2021"]
                    },
                    {
                        "name": "A/Prof Carol Ma",
                        "position": "Member",
                        "info": "Associate Professor, Head of Gerontology Programme Singapore University of Social Sciences",
                        "dateRanges": ["1 January 2021 to 31&nbsp;December&nbsp;2021"]
                    },
                ]
            },
            {
                "section": "Shared Procurement Programme Committee",
                "sectionDateRange": "1 April 2018 to 31 March 2021",
                "members": [
                    {
                        "name": "Mr Tan Kok Hwa",
                        "position": "Deputy Chairperson",
                        "info": "Assistant Director (Information Management and Support Services), Kwong Wai Shiu Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Chan Wah Tiong",
                        "position": "Member",
                        "info": "Chief Executive Officer (Nursing Home Cluster), St. Andrew’s Nursing Home",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Doris Tchen",
                        "position": "Former Member",
                        "info": "Former Vice-President (Nursing), Allium Healthcare",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Heidi Rafman",
                        "position": "Member",
                        "info": "Chief (Quality & Productivity Division/Healthy Ageing Department), Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Jeremy Lim",
                        "position": "Member",
                        "info": "Director (Operations), National Cancer Centre Singapore",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr John Chan",
                        "position": "Member",
                        "info": "Chief Executive Officer, Vanguard Healthcare Pte Ltd",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Mak Mun Fai",
                        "position": "Member",
                        "info": "Operations Manager, The Salvation Army, Peacehaven Nursing Home",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Ong Seok Peng",
                        "position": "Member",
                        "info": "Senior Pharmacist & Senior Manager (Central Supplies), St. Andrew’s Community Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Pauline Ang",
                        "position": "Member",
                        "info": "Nurse Educator, Ren Ci Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Susan Gui",
                        "position": "Member",
                        "info": "Director of Nursing, Villa Francis Home for the Aged",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Then Kim Yuan",
                        "position": "Member",
                        "info": "Administrator, Lee Ah Mooi Old Age Home",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Wong Yoke Yin",
                        "position": "Member",
                        "info": "Nurse Manager, All Saints Home",
                        "dateRanges": []
                    },
                ]
            },
            {
                "section": "Shared Procurement Programme Evaluation Panel",
                "sectionDateRange": "1 April 2018 to 31 March 2021",
                "members": [
                    {
                        "name": "Ms Susan Gui",
                        "position": "Co-Chairperson",
                        "info": "Director of Nursing, Villa Francis Home for the Aged",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Tan Kok Hwa",
                        "position": "Co-Chairperson",
                        "info": "Assistant Director (Information Management and Support Services), Kwong Wai Shiu Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Alison Sim Lei Choo",
                        "position": "Member",
                        "info": "Director of Nursing, St. Andrew’s Community Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Edwin Yeo",
                        "position": "Member",
                        "info": "Executive Director, Xiser Careserve",
                        "dateRanges": []
                    },
                    {
                        "name": "Sister Janigi Mohan",
                        "position": "Member",
                        "info": "Acting Head of Nursing, St Joseph’s Home",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Jenny Sim Teck Meh",
                        "position": "Member",
                        "info": "Chief Nurse, Ren Ci Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Joan Lee Kway",
                        "position": "Member",
                        "info": "Director of Nursing, St. Andrew’s Nursing Home (Queenstown)",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Joselito S. Iporac",
                        "position": "Member",
                        "info": "Assistant Director of Nursing, Lions Home for the Elders",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Ling Bee Sian",
                        "position": "Member",
                        "info": "Head of Home, Jamiyah Nursing Home (Darul Syifaa)",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Long Jane",
                        "position": "Member",
                        "info": "Director of Nursing, Singapore Christian Home",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Ranjit Singh",
                        "position": "Member",
                        "info": "Director of Nursing, Grace Lodge",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Raymond Lim",
                        "position": "Member",
                        "info": "Centre Director, NTUC Health Nursing Home (Chai Chee)",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms S. Indarani",
                        "position": "Member",
                        "info": "Head of Nursing, THK Nursing Home @ Hougang",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Winnie Koh",
                        "position": "Member",
                        "info": "Chief Executive Officer, Moral Home for Aged Sick",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Criss Ang",
                        "position": "Former Member",
                        "info": "Former Assistant Head (Operations), THK Nursing Home @ Hougang",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Emily Tan",
                        "position": "Former Member",
                        "info": "Former Assistant Manager (Operations), MWS Nursing Home Yew Tee",
                        "dateRanges": []
                    },
                    {
                        "name": "Sister Gillian Beins",
                        "position": "Former Member",
                        "info": "Director of Nursing, St. Joseph’s Home",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Satyaprakash Tiwari",
                        "position": "Former Member",
                        "info": "Former Executive Director/Consultant, Jamiyah Nursing Home (Darul Syifaa)",
                        "dateRanges": []
                    },
                ]
            },
            {
                "section": "Strategic Advisory Committee for Quality Improvement (SAC-QI) in The ILTC Sector",
                "sectionDateRange": "1 February 2018 to 31 January 2022",
                "members": [
                    {
                        "name": "A/Prof Tan Boon Yeow",
                        "position": "Chairperson",
                        "info": "Chief Executive Officer & Senior Consultant,\nSt. Luke’s Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "A/Prof Lee Kheng Hock",
                        "position": "Deputy Chairperson",
                        "info": "Medical Director, Bright Vision Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Angie Ng",
                        "position": "Member",
                        "info": "Director of Nursing, ECON Healthcare",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Cheong Choy Fong",
                        "position": "Member",
                        "info": "Director (Quality and Risk Management),\nKhoo Teck Puat Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Chin Soh Mun",
                        "position": "Member",
                        "info": "Director of Nursing, Dover Park Hospice",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Chow Mun Hong",
                        "position": "Member",
                        "info": "Director (Quality Management) & Senior Consultant, SingHealth Polyclinics",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Florence Chng",
                        "position": "Member",
                        "info": "Deputy Director (YCH Admin Office/Clinical Affairs), Yishun Community Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr James Low",
                        "position": "Member",
                        "info": "Senior Consultant (Geriatric Medicine),\nKhoo Teck Puat Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Jenny Sim Teck Meh",
                        "position": "Member",
                        "info": "Chief Nurse, Ren Ci Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Heidi Rafman",
                        "position": "Member",
                        "info": "Chief (Quality & Productivity Division/Healthy Ageing Department), Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Ng Sow Chun",
                        "position": "Member",
                        "info": "Deputy Director (Nursing Quality and Process Improvement and Operations), National University Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Dr Ng Wai Chong",
                        "position": "Member",
                        "info": "Chief Executive Officer, NWC Longevity Practice Clinical Programme Consultant, Tsao Foundation Consultant, Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Sairam Azad",
                        "position": "Member",
                        "info": "Deputy Director (Health & Senior Care), AWWA Ltd",
                        "dateRanges": []
                    },
                ]
            },
        ]
    },
    {
        sector: "Sector Funds",
        data: [
            {
                "section": "AIC Facility MediFund Committee",
                "sectionDateRange": "1 April 2019 to 31 March 2023",
                "members": [
                    {
                        "name": "Dr Loh Yik Hin",
                        "position": "Chairman",
                        "info": "Chief Executive Officer,\nSt. Andrew’s Community Hospital",
                    },
                    {
                        "name": "Mrs Wee Wan Joo",
                        "position": "Member",
                    },
                    {
                        "name": "Ms Lee Yoke Lan",
                        "position": "Member",
                    },
                    {
                        "name": "Mr Tan Kim Kwang",
                        "position": "Member",
                        "info": "Group Human Resource Director,\nEzion Holdings Limited",
                    },
                ]
            },
            {
                "section": "Community Silver Trust (CST) Evaluation Panel",
                "members": [
                    {
                        "name": "Mr R. Sinnakaruppan",
                        "position": "Co-Chairperson",
                        "info": "Chairman & Chief Executive Officer, of Singapore Education Academy Pte Ltd",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Dr Benjamin Koh",
                        "position": "Co-Chairperson",
                        "info": "Deputy Secretary (Development), Ministry of Health",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020", "1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "Ms Janice Ang",
                        "position": "Co-Chairperson",
                        "info": "Senior Executive Coach, IJ Martin & Co Ltd",
                        "dateRanges": ["1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "Dr Ang Peng Chye",
                        "position": "Member",
                        "info": "Director, Ang & Kong Psychiatric and Behavioural Medicine Clinic Pte Ltd",
                        "dateRanges": ["1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "Ms Angela Yak",
                        "position": "Member",
                        "info": "Group Director (Sector Strategy), National Council of Social Service",
                        "dateRanges": ["1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "A/Prof Angelique Chan",
                        "position": "Member",
                        "info": "Associate Professor (Department of Sociology), National University of Singapore <br>Executive Director, Centre for Ageing Research & Education",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020", "1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "Mr Anjan Ghosh",
                        "position": "Member",
                        "info": "Director (Service Planning & Development Group), National Council of Social Service",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Ms Carol Chua",
                        "position": "Member",
                        "info": "Director (Service Management and Resource), Ministry of Social & Family Development",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020", "1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "A/Prof Gerald Koh",
                        "position": "Member",
                        "info": "Director of Medical Undergraduate Education, Saw Swee Hock School of Public Health <br>Joint Associate Professor at Dean’s Office, Yong Loo Lin School of Medicine, NUS",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Mr Tan Kwang Cheak",
                        "position": "Member",
                        "info": "Chief Executive Officer, Agency for Integrated Care",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020", "1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "A/Prof Tan Thai Lian",
                        "position": "Member",
                        "info": "Divisional Chairman (Medicine), Senior Consultant, Tan Tock Seng Hospital",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Mr Toh Swee Chien",
                        "position": "Member",
                        "info": "Director (Enterprise Development Group, Enterprise Programmes Division, Manufacturing Division), Workforce Singapore",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Dr Wong Sweet Fun",
                        "position": "Member",
                        "info": "Chief Transformation Officer, Deputy Chairman (Medical Board), Clinical Director (Population Health & Community Transformation), Khoo Teck Puat Hospital",
                        "dateRanges": ["1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "Ms Woon Saet Nyoon",
                        "position": "Member",
                        "info": "Chief Executive, Temasek Foundation Cares",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020", "1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                    {
                        "name": "Dr Zuraimi Bin Mohamed Dahlan",
                        "position": "Member",
                        "info": "Chairman, Medifund Committee of Jamiyah Nursing Home Member, External Placement Board Review (Prisons) and Board of Visitors (Prisons-DRC) <br>Member, Tribunal of Maintenance of Parents",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020", "1 April 2020 to 31&nbsp;March&nbsp;2023"]
                    },
                ]
            },
            {
                "section": "Healthcare Productivity Fund (HPF) Community Care Approval Panel",
                "members": [
                    {
                        "name": "Mr Tan Kwang Cheak",
                        "position": "Chairperson",
                        "info": "Chief Executive Officer, Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Albert Hong",
                        "position": "Member",
                        "info": "Chief Operating Officer, Ren Ci Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Florence Chng",
                        "position": "Member",
                        "info": "Deputy Director (YCH Admin Office/Clinical Affairs), Yishun Community Hospital",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Heidi Rafman",
                        "position": "Member",
                        "info": "Chief (Quality & Productivity Division/Healthy Ageing Department), Agency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "A/Prof Kenny Tan",
                        "position": "Member",
                        "info": "Chief Executive Officer, St Luke’s Eldercare Ltd",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Lee Deqi",
                        "position": "Member",
                        "info": "Deputy Director (Manpower Planning and Strategy Division), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Matthew Lee",
                        "position": "Member",
                        "info": "Deputy Director (Subvention), Ministry of Health",
                        "dateRanges": []
                    },
                    {
                        "name": "Mrs Mina Lim",
                        "position": "Member",
                        "info": "Deputy Director, St. Andrew’s Senior Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Ms Ong Hui Ming",
                        "position": "Member",
                        "info": "Deputy Chief Executive Officer, Econ Healthcare (Asia) Limited",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Sairam Azad",
                        "position": "Member",
                        "info": "Deputy Director (Health & Senior Care), AWWA Ltd",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Soh Keng Taan",
                        "position": "Member",
                        "info": "Chief Information and Digital Officer,\nAgency for Integrated Care",
                        "dateRanges": []
                    },
                    {
                        "name": "Mr Then Kim Yuan",
                        "position": "Member",
                        "info": "Administrator, Lee Ah Mooi Old Age Home",
                        "dateRanges": []
                    },
                ]
            },
            {
                "section": "Tote Board Community Health Fund (TBCHF)",
                "members": [
                    {
                        "name": "Mr Ng How Yue",
                        "position": "Chairperson",
                        "info": "Permanent Secretary, Health Development 2nd Permanent Secretary, Ministry Of Health",
                        "dateRanges": ["1 March 2017 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Dr Benjamin Koh",
                        "position": "Chairperson",
                        "info": "Deputy Secretary (Development), Ministry of Health",
                        "dateRanges": ["1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Ang Peng Chye",
                        "position": "Member",
                        "info": "Director, Ang & Kong Psychiatric and Behavioural Medicine Clinic Pte Ltd",
                        "dateRanges": ["1 April 2011 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Dr Ang Seng Bin",
                        "position": "Member",
                        "info": "Head & Senior Consultant (Family Medicine Service & Menopause Unit), KK Women’s and Children’s Hospital <br>Board Member, Tote Board",
                        "dateRanges": ["1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Charlene Chang",
                        "position": "Member",
                        "info": "Group Director (Ageing Planning Office), Ministry of Health",
                        "dateRanges": ["1 April 2019 to 31&nbsp;March&nbsp;2020", "1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "A/Prof Gerald Koh",
                        "position": "Member",
                        "info": "Professor (Health Systems and Behavioural Sciences), Saw Swee Hock School of Public Health",
                        "dateRanges": ["1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Gerard Ee",
                        "position": "Member",
                        "info": "Chairman, Agency for Integrated Care",
                        "dateRanges": ["1 September 2018 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Mr Gilbert Tan Chye Hee",
                        "position": "Member",
                        "info": "Chief Executive Officer, NTUC, Employment and Employability Institute",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "A/Prof Ian Leong",
                        "position": "Member",
                        "info": "Deputy Divisional Chairman (Continuing and Community Care), Tan Tock Seng Hospital",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020", "1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Mr Lim Teck Yin",
                        "position": "Member",
                        "info": "Chief Executive Officer, Sport Singapore",
                        "dateRanges": ["1 April 2015 to 31&nbsp;March&nbsp;2020", "1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Long Chey May",
                        "position": "Member",
                        "info": "Group Chief Patient Officer, National University Health System",
                        "dateRanges": ["1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "A/Prof Philip Yap",
                        "position": "Member",
                        "info": "Senior Consultant (Geriatric Medicine), Khoo Teck Puat Hospital",
                        "dateRanges": ["1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Mr Sim Gim Guan",
                        "position": "Member",
                        "info": "Chief Executive Officer, National Council of Social Service",
                        "dateRanges": ["1 November 2013 to 31&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Ms Soh Swee Ping",
                        "position": "Member",
                        "info": "Chief Executive Officer, Council for Third Age",
                        "dateRanges": ["1 April 2018 to 31&nbsp;March&nbsp;2020", "1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Dr Sue-Anne Toh Ee Shiow",
                        "position": "Member",
                        "info": "Centre Director, Singapore Population HEalth impRovement Centre (SPHERiC) <br>Clinical Director (Regional Health System Planning and Development), National University Health System",
                        "dateRanges": ["1 April 2018 to 1&nbsp;March&nbsp;2020"]
                    },
                    {
                        "name": "Mr Tan Kwang Cheak",
                        "position": "Member",
                        "info": "Chief Executive Officer, Agency for Integrated Care",
                        "dateRanges": ["1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Ms Tan Li San",
                        "position": "Member",
                        "info": "Chief Executive Officer, National Council of Social Service",
                        "dateRanges": ["1 October 2020 to 31&nbsp;December&nbsp;2022"]
                    },
                    {
                        "name": "Mr Thali Koattiath Udairam",
                        "position": "Member",
                        "info": "Chief Operating Officer, Sheares Healthcare Management Pte Ltd <br>Board Member, Tote Board",
                        "dateRanges": ["1 April 2015 to 31&nbsp;March&nbsp;2020"]
                    },
                ]
            },
        ]
    }
]